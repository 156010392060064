// Generated by Framer (d275c2b)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["spyNxJ9D0"];

const serializationHash = "framer-cibbZ"

const variantClassNames = {spyNxJ9D0: "framer-v-162vebl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "spyNxJ9D0", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-162vebl", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"spyNxJ9D0"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-ezdgsv"} data-framer-name={"shape-arrow 12"} layout={"position"} layoutDependency={layoutDependency} layoutId={"IewK6B3PR"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 24 24\"><path d=\"M 12.426 23.262 L 21.262 14.426 C 21.555 14.134 21.555 13.659 21.262 13.366 C 20.969 13.073 20.494 13.073 20.201 13.366 L 12.646 20.921 L 12.646 2.104 C 12.646 1.69 12.31 1.354 11.896 1.354 C 11.482 1.354 11.146 1.69 11.146 2.104 L 11.146 20.921 L 3.591 13.366 C 3.298 13.073 2.823 13.073 2.53 13.366 C 2.238 13.659 2.238 14.134 2.53 14.426 L 11.366 23.262 C 11.659 23.555 12.134 23.555 12.426 23.262 Z\" fill=\"var(--token-be079bad-eabd-4234-8a56-5579c7057187, rgb(0, 0, 0)) /* {&quot;name&quot;:&quot;Primary / Black&quot;} */\"></path></svg>"} svgContentId={9058007833} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-cibbZ.framer-1wbnp5e, .framer-cibbZ .framer-1wbnp5e { display: block; }", ".framer-cibbZ.framer-162vebl { height: 24px; overflow: visible; position: relative; width: 24px; }", ".framer-cibbZ .framer-ezdgsv { flex: none; height: 24px; left: calc(50.00000000000002% - 24px / 2); position: absolute; top: calc(50.00000000000002% - 24px / 2); width: 24px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerAFwYH2e9P: React.ComponentType<Props> = withCSS(Component, css, "framer-cibbZ") as typeof Component;
export default FramerAFwYH2e9P;

FramerAFwYH2e9P.displayName = "Arrow - Down - 24";

FramerAFwYH2e9P.defaultProps = {height: 24, width: 24};

addFonts(FramerAFwYH2e9P, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})